import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import closeButtonPic from '../../assets/designSystem/icons/close.svg';
import Button from '../Button/Button';
import './modal.css';

function Modal({ onCloseModal }) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const redirectHandler = () => {
    navigate('/homepage', { state: location.state });
  };

  return (
    <>
      <div className="modal__backdrop" role="presentation" onClick={onCloseModal} />
      <div className="modal__popup">
        <div className="modal__content">
          <img
            src={closeButtonPic}
            alt="close button"
            className="modal__closeBtn"
            role="presentation"
            onClick={onCloseModal}
          />
          <div>
            <h3 className="modal__header">{t('modalcomponent.h3')}</h3>
            <p className="modal__paragraph">{t('modalcomponent.p')}</p>
          </div>
          <div className="modal__containerButtons">
            <Button width="fullWidth" hierarchy="primary" onClick={onCloseModal}>
              {t('modalcomponent.button1')}
            </Button>
            <Button width="fullWidth" hierarchy="tertiary" onClick={redirectHandler}>
              {t('modalcomponent.button2')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Modal;

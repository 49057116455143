// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy__first-title {
    margin-bottom: 8px;
}

.privacy__titles {
    margin-top: 32px;
    margin-bottom: 8px;
}`, "",{"version":3,"sources":["webpack://./src/pages/PrivacyPolicy/privacypolicy.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".privacy__first-title {\n    margin-bottom: 8px;\n}\n\n.privacy__titles {\n    margin-top: 32px;\n    margin-bottom: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

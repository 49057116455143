import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import EnglishJsonFile from './locale/en.json';
import SwahiliJsonFile from './locale/swa.json';

const resources = {
  English: { translation: EnglishJsonFile },
  Swahili: { translation: SwahiliJsonFile },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'English',
    resources,
  });

export default i18n;

import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/designSystem/logo/logo-white2.svg';
import './loading.css';

export default function Loading() {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.state?.nextPage) {
      setTimeout(() => {
        navigate(location.state.nextPage);
      }, 2000);
    }
  }, []);

  return (
    <div className="container loadingPage">
      <div className="loadingPage__content">
        <img height={40} src={logo} alt="Kito logo" />
        <div className="loadingPage__animation">
          <span style={{ '--n': 1, background: 'var(--feedback-colors-yellow)' }} />
          <span style={{ '--n': 2, background: 'var(--feedback-colors-yellow)' }} />
          <span style={{ '--n': 3, background: 'var(--feedback-colors-orange)' }} />
          <span style={{ '--n': 4, background: 'var(--feedback-colors-orange)' }} />
          <span style={{ '--n': 5, background: 'var(--feedback-colors-red)' }} />
          <span style={{ '--n': 6, background: 'var(--feedback-colors-red)' }} />
          <span style={{ '--n': 7, background: 'var(--brand-colors-primary-3)' }} />
          <span style={{ '--n': 8, background: 'var(--brand-colors-primary-3)' }} />
        </div>
      </div>
    </div>
  );
}

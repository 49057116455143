// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.symptom__content {
  gap: var(--spacing-l);
  align-items: center;
  text-align: center;
}

.symptom__buttons {
  width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
  max-width: 65vh;
  max-height: 65vh;
	gap: var(--spacing-xs);
}`, "",{"version":3,"sources":["webpack://./src/pages/Symptom/symptom.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,WAAW;CACZ,aAAa;CACb,8BAA8B;EAC7B,eAAe;EACf,gBAAgB;CACjB,sBAAsB;AACvB","sourcesContent":[".symptom__content {\n  gap: var(--spacing-l);\n  align-items: center;\n  text-align: center;\n}\n\n.symptom__buttons {\n  width: 100%;\n\tdisplay: grid;\n\tgrid-template-columns: 1fr 1fr;\n  max-width: 65vh;\n  max-height: 65vh;\n\tgap: var(--spacing-xs);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

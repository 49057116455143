/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';
import ColoredBar from '../../components/ColoredBar/ColoredBar';
import Nav from '../../components/Nav/Nav';
import './termsandconditions.css';

function TermsAndConditions() {
  return (
    <div className="container">
      <Nav backArrow label="Terms & Conditions" />
      <ColoredBar />
      <div className="containerContent">
        <ol type="1">
          <li>
            <h4 className="terms__titles">Welcome to Kito Health</h4>
            <ol type="a">
              <li>
                These terms and conditions form an agreement between you, as the user,
                and Nixon Global Health Foundation, as the service provider of the
                Kito Health services and owner of the Kito Health software, website and application
                (hereinafter referred to, respectively, as the &ldquo;Terms and Conditions&rdquo;,the &ldquo;Agreement&rdquo;, the
                &ldquo;User&rdquo;, the &ldquo;Foundation&rdquo; or &ldquo;Kito Health&rdquo;, and &ldquo;Kito Health
                Services&rdquo;). Together, referred as the Parties.
              </li>
              <br />
              <li>
                These Terms and Conditions specify the principles under which
                Kito Health Services are provided, through its website and application(
                &ldquo;
                <Link to="https://www.kitohealth.org/">https://www.kitohealth.org/</Link>
                &rdquo;
                hereinafter referred to, respectively, as the &ldquo;Website&rdquo;, and
                the &ldquo;Application&rdquo; or &ldquo;App&rdquo;), and
                establish the rights and obligations of both Parties;
              </li>
              <br />
              <li>
                By using our Website and Application the User agrees to these Terms and Conditions
                along with our Privacy Policy, available at&nbsp;
                <Link to="/policy">Policy Privacy.</Link>
              </li>
              <br />
              <li>
                The Foundation has the right to prevent the User from using Kito Health Services in
                case the User fails to comply with these Terms and Conditions, or for any other reason.
              </li>
            </ol>
          </li>
          <br />
          <li>
            <h4 className="terms__titles">Our services</h4>
            <ol type="a">
              <li>
                Kito Health Services are provided free of charge and made available through the Website and/or the Application;
              </li>
              <br />
              <li>
                Kito Health Services consist, mainly, on granting the use of a pediatric symptom checker which allows for a
                tailored advice and health information (hereinafter referred to as “User Advice”), based on a calculated triage
                risk score (hereinafter referred to as “User Output”);
              </li>
              <br />
              <li>
                User Outputs are automatically generated by answering a series of questions (hereinafter referred to as
                “Questionnaire”) regarding a child&apos;s symptoms;
              </li>
              <br />
              <li>
                The Questionnaire is a set of questions based on the main symptom, child&apos;s age and gender,
                whereby possible related symptoms, predispositions and history of medical conditions, as these
                are captured to generate the most accurate User Output;
              </li>
              <br />
              <li>
                The User Advice includes information and primary medical advice based on the answers given throughout
                the Questionnaire and based on the User Output;
              </li>
              <br />
              <li>
                Kito Health or its Services will not issue medical prescriptions, of any kind;
              </li>
              <br />
              <li>
                Kito Health or its Services will not provide online or face-to-face medical consultations;
              </li>
              <br />
              <li>
                Kito Health or its Services shall not replace a medical consultation, and shall not provide
                medical diagnosis. You, as the User, should never disregard a health professional&apos;s advice
                over the information provided by Kito Health;
              </li>
              <br />
              <li>
                Kito Health and its Services must not be used in case of emergency. In such cases, the User should
                seek for immediate medical help, provided by recognized and certified medical professionals;
              </li>
              <br />
              <li>
                Kito Health reserves the right to freely alter, at any time, the content and / or form
                through which it displays information to / and interacts with the User;
              </li>
              <br />
              <li>
                Kito Health may, at any time, interrupt the operation of any of its Services, for maintenance
                purposes or for any other reason or event deemed appropriate or convenient.
              </li>
            </ol>
          </li>
          <br />
          <li>
            <h4 className="terms__titles">Use of the Website and/or Application</h4>
            <ol type="a">
              <li>
                In order to benefit from Kito Health Services and use the Website or the Application, the User should
                have use an electronic device that he/she/they control, with access to the internet and with technical
                characteristics that are able to allow the Website or the Application to properly run;
              </li>
              <br />
              <li>
                The User must be of age according to the law of his/her/their nationality;
              </li>
              <br />
              <li>
                The User may benefit from Kito Health Services and use and interact with the Website and / or the Application
                on behalf of a minor if, at the moment of use and interaction, is considered by law to be the legal responsible
                for the latter&apos;s care;
              </li>
              <br />
              <li>
                The User does not need to create an account / profile in order to use the Website or the Application;
              </li>
              <br />
              <li>
                The User may not use the Website and / or the Application for commercial reasons, or for any other reasons
                strange to the purposes set forth in these Terms and Conditions;
              </li>
              <br />
              <li>
                The User must not use any content provided in the Website or the Application, being any copy, reproduction,
                or modification, in full or partial, strictly forbidden;
              </li>
              <br />
              <li>
                The User must not use any content provided in the Website or the Application for commercial or
                advertising purposes, or any other strange and unauthorized purposes;
              </li>
              <br />
              <li>
                The attempt to change or break any security system of the Website or the Application, or to alter its
                functioning, shall result in termination of this Agreement, to us being reserved the right to resort to
                all legal and judicial means within our reach for the protection of our rights on the platform and its
                source code.
              </li>
            </ol>
          </li>
          <br />
          <li>
            <h4 className="terms__titles">Kito Health and personal data</h4>
            <ol type="a">
              <li>
                In order to provide accurate advice, Kito Health, through the Website or the Application, will
                request the input of some personal data of the subject to whom the User Advice refers, such as
                gender, age group and medical-related details;
              </li>
              <br />
              <li>
                Kito Health is in compliance with General Data Protection Regulation - Regulation 2016/679, for
                the protection of natural persons with regard to the processing of personal data and on the free
                movement of such data, and repealing Directive 95/46/EC (hereinafter referred to as “GDPR”);
              </li>
              <br />
              <li>
                In compliance with GDPR, Kito Health collects, processes, stores, and protects your personal
                data, in accordance with the applicable EU law;
              </li>
              <br />
              <li>
                You may find more detailed information on how and for what purposes we collect and use the information you provide,
                as well as the use of cookies, by reading our Privacy Policy,  available at&nbsp;
                <Link to="/policy">Policy Privacy.</Link>
              </li>
            </ol>
          </li>
          <br />
          <li>
            <h4 className="terms__titles">Liability</h4>
            <ol type="a">
              <li>
                The Foundation assumes no liability for the use of Kito Health Service through its Website
                and / or Application, for any losses or damages, temporary or permanent, of any kind and nature,
                that the User may face or feel entitled to, related to the Services the first provides, in the
                form and designated as “User Advice”, which is subject to errors of any sort. Therefore, the User
                should never act blindly in accordance with the information or advice provided by Kito Health Services;
              </li>
              <br />
              <li>
                Without prejudice to the former paragraph, it should be of perfect knowledge of
                the User that the Foundation will never, in any circumstance, be liable for: (i) the
                misuse or misunderstanding of the information obtained through Kito Health Services; (ii) damage
                caused in the case of such misuse or misunderstanding; (iii) poor performance or non-performance
                of Kito Health Services, through the Website and / or the Application, due to outdated or defective
                hardware or software, or for any other reason; (iv) any Force Majeure, or any extraordinary external
                event, that may cause interruption or malfunction of Kito Health Services, through the Website and / or
                the Application; (v) misuse of the content provided through the Website and / or the Application by any
                other party not owned or controlled by the Foundation.
              </li>
              <br />
              <li>
                The User shall perceive these Terms of Service as the one and only binding terms, that foresee,
                identify and limit the User&apos;s rights and obligations as user of Kito Health Services, through the
                Website and / or the Application, and the Foundation rights and obligations as the service provider
                of Kito Health Services, through the Website and / or the Application.
              </li>
            </ol>
          </li>
          <br />
          <li>
            <h4 className="terms__titles">Changes to these Terms and Conditions</h4>
            <ol type="a">
              <li>
                The Foundation reserves the right to change these Terms and Conditions,
                freely, at any time and for whatever reason, as it improves and / or adds
                new features to the current Services;
              </li>
              <br />
              <li>
                The User may, at any time, read the current version of these Terms and Conditions on the Website;
              </li>
              <br />
              <li>
                The use of Kito Health Services, through the Website and / or the Application by
                the User always presumes its full knowledge and acceptance of the current version of the
                Terms and Conditions. By continuing to use Kito Health Services, through the Website and / or the
                Application, the User agrees to the new Terms and Conditions;
              </li>
              <br />
              <li>
                The User shall be notified of any changes to these Terms and Conditions, whenever these affect
                his/her/their rights as a User of Kito Health Services, through the Website and / or the Application;
              </li>
              <br />
              <li>
                In case the User does not agree with the amendments put in place to the Terms and Conditions,
                he/she/they shall terminate or withdraw from this Agreement, and must immediately stop using Kito
                Health Services, the Website and the Application.
              </li>
            </ol>
          </li>
          <br />
          <li>
            <h4 className="terms__titles">Termination and/or Withdrawal</h4>
            <ol type="a">
              <li>
                The User is allowed, at any time, to terminate or withdraw from this Agreement
                (and from these Terms and Conditions);
              </li>
              <br />
              <li>
                The User may terminate or withdraw from this Agreement (and from these Terms and Conditions)
                by ceasing use of Kito Health Services, through the Website and the Application, deleting / uninstalling
                the Application from any of his/her/their controlled devices;
              </li>
              <br />
              <li>
                The Foundation reserves the right to freely and immediately terminate this Agreement, with no need to present
                or call for a cause or event, meaning that the Foundation may, at any time and without the need to justify,
                terminate this Agreement and cease to provide any Kito Health Services to the User, whether it be for the cases
                when the User fails to comply with or acts in breach of any of these Terms and Conditions, or for any other reason
                as the Foundation may see fit.
              </li>
            </ol>
          </li>
          <br />
          <li>
            <h4 className="terms__titles">Intellectual Property</h4>
            <ol type="a">
              <li>
                The Foundation owns all proprietary rights derived from Kito Health Services, through the Website
                and / or the Application, and of all the information, media and content provided in such platforms
                or, when it is not, has the legal right to use such information, media and content;
              </li>
              <br />
              <li>
                Kito Health Services, the Website and / or the Application, are protected by copyright and any unauthorized
                violation or infringement of such rights may face legal prosecution;
              </li>
              <br />
              <li>
                Kito Health Services, the Website and / or the Application, are  owned by the Foundation
                (Nixon Global Health Foundation).
              </li>
            </ol>
          </li>
          <br />
          <li>
            <h4 className="terms__titles">Contact</h4>
            <ol type="a">
              <li>
                The User may contact the team of Kito Health directly on the Website&apos;s contact form or through email,
                sent to: “info@kitohealth.org”.
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  );
}

export default TermsAndConditions;

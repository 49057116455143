import React from 'react';
import { useTranslation } from 'react-i18next';
import phone from '../../../assets/designSystem/icons/icon_phone.png';
import closeButtonPic from '../../../assets/designSystem/icons/close.svg';
import Button from '../../../components/Button/Button';
import './popup.css';

export default function ambulance({ callback }) {
  const { t } = useTranslation();
  return (
    <>
      <div className="modal__backdrop" />
      <div className="modal__popup">
        <div className="popup__container">
          <img
            src={closeButtonPic}
            alt="close button"
            className="popup__closeBtn"
            role="presentation"
            width="24"
            onClick={callback}
          />
          <div className="popup__content">
            <h4 className="popup__title">{t('popUps.ambulance.title')}</h4>
            <p>
              {t('popUps.ambulance.p1')}
              <div className="popup__numbers">
                <a href="tel:0800721316" className="phone__btn__button">
                  <span><img src={phone} alt="phone icon" /></span>
                  <span><p className="phone__btn__number">{t('popUps.ambulance.number1')}</p></span>
                </a>
                <a href="tel:1508" className="phone__btn__button">
                  <span><img src={phone} alt="phone icon" /></span>
                  <span><p className="phone__btn__number">{t('popUps.ambulance.number2')}</p></span>
                </a>
              </div>
            </p>
          </div>
          <div className="popup__button">
            <Button width="fullWidth" hierarchy="primary" onClick={callback}>{t('popUps.button')}</Button>
          </div>
        </div>
      </div>
    </>
  );
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.language__radios{
  margin: var(--spacing-l) auto 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 65vh;
  max-height: 65vh;
  gap: var(--spacing-xs);
}

.language__page {
  justify-content: space-between;
}

.language__page__button {
  margin-bottom: 24px;
}

.language__paragraph {
  color: var(--neutral-4);
  margin-top: 8px;
  margin-bottom: 32px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Language/language.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,eAAe;EACf,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,eAAe;EACf,mBAAmB;AACrB","sourcesContent":[".language__radios{\n  margin: var(--spacing-l) auto 0;\n  width: 100%;\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  max-width: 65vh;\n  max-height: 65vh;\n  gap: var(--spacing-xs);\n}\n\n.language__page {\n  justify-content: space-between;\n}\n\n.language__page__button {\n  margin-bottom: 24px;\n}\n\n.language__paragraph {\n  color: var(--neutral-4);\n  margin-top: 8px;\n  margin-bottom: 32px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

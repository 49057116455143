import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AddOtherSymptomAPI } from '../../API/api';
import Button from '../../components/Button/Button';
import ColoredBar from '../../components/ColoredBar/ColoredBar';
import Nav from '../../components/Nav/Nav';
import Loading from '../Loading/Loading';
import './otherSymptom.css';

function OtherSymptom() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const inputChangeHandler = e => {
    setInputValue(e.target.value);
  };

  const RedirectHandler = async () => {
    setIsLoading(true);
    const data = await AddOtherSymptomAPI(inputValue);
    const response = await data.json();
    if (!data.ok) {
      navigate('/error', { state: { code: data.status, message: JSON.stringify(response) } });
    } else {
      navigate('/finalMessage', { state: { feedback: true } });
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && (
        <div className="container">
          <Nav backArrow logo />
          <ColoredBar />
          <div className="containerContent otherSymptom__content">
            <p className="otherSymptom__paragraph">{t('othersymptom.p1')}</p>
            <h3 className="otherSymptom__h3">{t('othersymptom.h3')}</h3>
            <p className="otherSymptom__paragraph">{t('othersymptom.p2')}</p>
            <input
              type="text"
              className="otherSymptom__input"
              placeholder="Enter your suggestion"
              onChange={inputChangeHandler}
            />
            <Button width="fullWidth" hierarchy="primary" disabled={!inputValue} onClick={RedirectHandler}>
              {t('othersymptom.button')}
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export default OtherSymptom;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loadingPage {
  background: var(--brand-colors-primary-4);
}

.loadingPage__content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.loadingPage__animation {
  height: 100px;
  position: relative;
}

.loadingPage__animation span {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: rotate(calc(var(--n) * 45deg));
  animation: animate_dot 1.2s linear infinite;
}

@keyframes animate_dot {
  0% {
    transform: rotate(calc(var(--n) * 45deg)) translate(0);
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.8);
  }

  30% {
    transform: rotate(calc(var(--n) * 45deg + 90deg)) translate(30px, 0);
    box-shadow: none;
  }

  65% {
    transform: rotate(calc(var(--n) * 45deg + 270deg)) translate(30px, 0);
  }

  90% {
    transform: rotate(calc(var(--n) * 45deg + 360deg)) translate(0);
  }

  100% {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.8);
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Loading/loading.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;AAC3C;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,gCAAgC;AAClC;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,kBAAkB;EAClB,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,yCAAyC;EACzC,2CAA2C;AAC7C;;AAEA;EACE;IACE,sDAAsD;IACtD,0DAA0D;EAC5D;;EAEA;IACE,oEAAoE;IACpE,gBAAgB;EAClB;;EAEA;IACE,qEAAqE;EACvE;;EAEA;IACE,+DAA+D;EACjE;;EAEA;IACE,0DAA0D;EAC5D;AACF","sourcesContent":[".loadingPage {\n  background: var(--brand-colors-primary-4);\n}\n\n.loadingPage__content {\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n}\n\n.loadingPage__animation {\n  height: 100px;\n  position: relative;\n}\n\n.loadingPage__animation span {\n  width: 8px;\n  height: 8px;\n  border-radius: 50%;\n  position: absolute;\n  top: 50%;\n  right: 50%;\n  transform: rotate(calc(var(--n) * 45deg));\n  animation: animate_dot 1.2s linear infinite;\n}\n\n@keyframes animate_dot {\n  0% {\n    transform: rotate(calc(var(--n) * 45deg)) translate(0);\n    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.8);\n  }\n\n  30% {\n    transform: rotate(calc(var(--n) * 45deg + 90deg)) translate(30px, 0);\n    box-shadow: none;\n  }\n\n  65% {\n    transform: rotate(calc(var(--n) * 45deg + 270deg)) translate(30px, 0);\n  }\n\n  90% {\n    transform: rotate(calc(var(--n) * 45deg + 360deg)) translate(0);\n  }\n\n  100% {\n    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.8);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button/Button';
import ListRadio from '../../components/Radio/ListRadio';
import doubtIcon from '../../assets/designSystem/icons/icon_doubt.svg';
import './questioncard.css';

function QuestionCard({
  wdim, question, answers, callback, showWdimPage, code,
}) {
  const { t } = useTranslation();
  const handleOptionClick = e => {
    setTimeout(() => {
      callback(e);
    }, 250);
  };

  return (
    <div className="questionCard__container">
      <div className={`questionCard__titleContainer ${code}`}>
        <h3>{question}</h3>
        {wdim
          && (
            <Button width="smallWidth" hierarchy="tertiary" prefix={doubtIcon} onClick={showWdimPage}>
              {t('questioncard.wdim')}
            </Button>
          )}
      </div>
      <div className="questionCard__options">
        {answers.map((answer, index) => (
          <ListRadio name="option" key={index} value={index + 1} label={answer.text} onChange={handleOptionClick} />
        ))}
      </div>
    </div>
  );
}

export default QuestionCard;

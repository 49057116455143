// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reassurance__page__content{
    padding-top: var(--spacing-l);
    padding-bottom: var(--spacing-m);
    display: flex;
    justify-content: space-between;
    text-align: center;
}
.reassurance__page__content__text__p{
    margin-top: var(--spacing-xs);
}
.bottom-wrapper_reassurance > button {
    margin-bottom: var(--spacing-s);
}`, "",{"version":3,"sources":["webpack://./src/pages/Reassurance/reassurance.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,gCAAgC;IAChC,aAAa;IACb,8BAA8B;IAC9B,kBAAkB;AACtB;AACA;IACI,6BAA6B;AACjC;AACA;IACI,+BAA+B;AACnC","sourcesContent":[".reassurance__page__content{\n    padding-top: var(--spacing-l);\n    padding-bottom: var(--spacing-m);\n    display: flex;\n    justify-content: space-between;\n    text-align: center;\n}\n.reassurance__page__content__text__p{\n    margin-top: var(--spacing-xs);\n}\n.bottom-wrapper_reassurance > button {\n    margin-bottom: var(--spacing-s);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

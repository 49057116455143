import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import ColoredBar from '../../components/ColoredBar/ColoredBar';
import errorPic from '../../assets/designSystem/icons/error.svg';
import './errorhandler.css';

function ErrorHandler() {
  const navigate = useNavigate();
  const location = useLocation();
  const code = location.state?.code;
  const message = location.state?.message;
  const handleRedirect = useCallback(() => {
    navigate('/homepage');
  }, []);

  return (
    <div className="container">
      <div className="containerContent errorHandler__content">
        <div className="errorHandler__innerContent">
          <img src={errorPic} alt="error" width="64" height="64" />
          <div className="errorHandler__textContent">
            <h1>{code || 404}</h1>
            <h3>Sorry, there was an error</h3>
            <p>
              We are sorry, the page you requested could not be found.
              Please go back to the homepage
            </p>
            <p className="small errorHandler__smallText">{message || 'No details.'}</p>
          </div>
        </div>
        <Button width="fullWidth" hierarchy="primary" onClick={handleRedirect}>Go back to homepage</Button>
      </div>
      <ColoredBar big />
    </div>
  );
}

export default ErrorHandler;

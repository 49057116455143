import React from 'react';
import closeButtonPic from '../../../assets/designSystem/icons/close.svg';
import Button from '../../../components/Button/Button';
import './popup.css';

export default function ors({ callback }) {
  return (
    <>
      <div className="modal__backdrop" />
      <div className="modal__popup">
        <div className="popup__container">
          <img
            src={closeButtonPic}
            alt="close button"
            className="popup__closeBtn"
            role="presentation"
            width="24"
            onClick={callback}
          />
          <div className="popup__content">
            <h4 className="popup__title">Make your rehydration salts</h4>
            <ul className="popup__ul">
              <li>Take one litre of safe drinking water. or lightly salted rice water </li>
              <li>
                In order to ensure the water is safe, boil it for 5 minutes and allow it to cool.
              </li>
              <li>Add half a small spoon of salt</li>
              <li>Add 6 level small spoons of sugar</li>
              <li>Mix to dissolve</li>
            </ul>
          </div>
          <div className="popup__button">
            <Button width="fullWidth" hierarchy="primary" onClick={callback}>Okay, I got it</Button>
          </div>
        </div>
      </div>
    </>
  );
}

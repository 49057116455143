// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `nav {
  display: flex;
  justify-content: space-between;
  background: var(--brand-colors-secondary-1);
  padding: 16px;
}

.nav__empty-div {
  width: 25px;
}

.nav__label {
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Nav/nav.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,2CAA2C;EAC3C,aAAa;AACf;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,SAAS;AACX","sourcesContent":["nav {\n  display: flex;\n  justify-content: space-between;\n  background: var(--brand-colors-secondary-1);\n  padding: 16px;\n}\n\n.nav__empty-div {\n  width: 25px;\n}\n\n.nav__label {\n  margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

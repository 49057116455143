// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu__container {
  padding: 32px 0 0;
}

.menu__ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.menu__itemContainer {
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  padding: 16px 16px;
  color: var(--brand-colors-secondary-5);
  border-bottom: 1px solid var(--neutral-2);
}`, "",{"version":3,"sources":["webpack://./src/pages/Menu/menu.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,SAAS;EACT,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,qBAAqB;EACrB,kBAAkB;EAClB,sCAAsC;EACtC,yCAAyC;AAC3C","sourcesContent":[".menu__container {\n  padding: 32px 0 0;\n}\n\n.menu__ul {\n  margin: 0;\n  padding: 0;\n  list-style: none;\n}\n\n.menu__itemContainer {\n  display: flex;\n  justify-content: space-between;\n  text-decoration: none;\n  padding: 16px 16px;\n  color: var(--brand-colors-secondary-5);\n  border-bottom: 1px solid var(--neutral-2);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

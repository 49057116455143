import React from 'react';
import { useTranslation } from 'react-i18next';
import closeButtonPic from '../../../assets/designSystem/icons/close.svg';
import Button from '../../../components/Button/Button';
import './popup.css';

export default function paracetamol({ babyOrChild, callback }) {
  const { t } = useTranslation();
  return (
    <>
      <div className="modal__backdrop" />
      <div className="modal__popup">
        <div className="popup__container">
          <img
            src={closeButtonPic}
            alt="close button"
            className="popup__closeBtn"
            role="presentation"
            width="24"
            onClick={callback}
          />
          <div className="popup__content">
            <h4 className="popup__title">{t('popUps.paracetamol.title')}</h4>
            <table className="popup__table">
              <thead>
                <tr>
                  <th>{t(`popUps.paracetamol.${babyOrChild}.tableTh1`)}</th>
                  <th>{t('popUps.paracetamol.tableTh2')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t('popUps.paracetamol.tableTd11')}</td>
                  <td>{t('popUps.paracetamol.tableTd12')}</td>
                </tr>
                <tr>
                  <td>{t('popUps.paracetamol.tableTd21')}</td>
                  <td>{t('popUps.paracetamol.tableTd22')}</td>
                </tr>
                <tr>
                  <td>{t('popUps.paracetamol.tableTd31')}</td>
                  <td>{t('popUps.paracetamol.tableTd32')}</td>
                </tr>
                <tr>
                  <td>{t('popUps.paracetamol.tableTd41')}</td>
                  <td>{t('popUps.paracetamol.tableTd42')}</td>
                </tr>
                <tr>
                  <td>{t('popUps.paracetamol.tableTd51')}</td>
                  <td>{t('popUps.paracetamol.tableTd52')}</td>
                </tr>
                <tr>
                  <td>{t('popUps.paracetamol.tableTd61')}</td>
                  <td>{t('popUps.paracetamol.tableTd62')}</td>
                </tr>
                <tr>
                  <td>{t('popUps.paracetamol.tableTd71')}</td>
                  <td>{t('popUps.paracetamol.tableTd72')}</td>
                </tr>
                <tr>
                  <td>{t('popUps.paracetamol.tableTd81')}</td>
                  <td>{t('popUps.paracetamol.tableTd82')}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="popup__button">
            <Button width="fullWidth" hierarchy="primary" onClick={callback}>{t('popUps.button')}</Button>
          </div>
        </div>
      </div>
    </>
  );
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/designSystem/icons/checkboxSquare.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.agreement__container {
  position: relative;
  margin-bottom: 80px;
}

.agreement__ul {
  margin: 0px;
  padding-left: 30px;
  font-size: var(--font-size-default);
  font-weight: var(--font-weight-default);
  line-height: var(--line-height-lg);
}

.agreement__checkboxLabel {
  margin-top: 16px;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  gap: 8px;
  font-size: var(--font-size-default);
  font-weight: var(--font-weight-default);
  line-height: var(--line-height-lg);
}

.agreement__checkboxInput {
  appearance: none;
  width: 20px;
  height: 20px;
  outline: var(--border-width-hairline) solid var(--brand-colors-primary-3);
  border-radius: 6px;
  vertical-align:middle;
}

.agreement__checkboxInput:checked::before {
  position: absolute;
  content: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.agreement__continueBtn {
  position:fixed;
  bottom:0;
  width: calc(100% - var(--spacing-l));
	padding-top: var(--spacing-l);
	padding-bottom: var(--spacing-m);
  background:  linear-gradient(180deg, rgba(250, 252, 253, 0) 0%, #FAFCFD 22.22%, #FAFCFD 100%);
}
`, "",{"version":3,"sources":["webpack://./src/pages/Agreement/agreement.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,mCAAmC;EACnC,uCAAuC;EACvC,kCAAkC;AACpC;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,gCAAgC;EAChC,sBAAsB;EACtB,QAAQ;EACR,mCAAmC;EACnC,uCAAuC;EACvC,kCAAkC;AACpC;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,YAAY;EACZ,yEAAyE;EACzE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,gDAAkE;AACpE;;AAEA;EACE,cAAc;EACd,QAAQ;EACR,oCAAoC;CACrC,6BAA6B;CAC7B,gCAAgC;EAC/B,6FAA6F;AAC/F","sourcesContent":[".agreement__container {\n  position: relative;\n  margin-bottom: 80px;\n}\n\n.agreement__ul {\n  margin: 0px;\n  padding-left: 30px;\n  font-size: var(--font-size-default);\n  font-weight: var(--font-weight-default);\n  line-height: var(--line-height-lg);\n}\n\n.agreement__checkboxLabel {\n  margin-top: 16px;\n  display: grid;\n  grid-template-columns: auto auto;\n  justify-content: start;\n  gap: 8px;\n  font-size: var(--font-size-default);\n  font-weight: var(--font-weight-default);\n  line-height: var(--line-height-lg);\n}\n\n.agreement__checkboxInput {\n  appearance: none;\n  width: 20px;\n  height: 20px;\n  outline: var(--border-width-hairline) solid var(--brand-colors-primary-3);\n  border-radius: 6px;\n  vertical-align:middle;\n}\n\n.agreement__checkboxInput:checked::before {\n  position: absolute;\n  content: url('../../assets/designSystem/icons/checkboxSquare.svg');\n}\n\n.agreement__continueBtn {\n  position:fixed;\n  bottom:0;\n  width: calc(100% - var(--spacing-l));\n\tpadding-top: var(--spacing-l);\n\tpadding-bottom: var(--spacing-m);\n  background:  linear-gradient(180deg, rgba(250, 252, 253, 0) 0%, #FAFCFD 22.22%, #FAFCFD 100%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import './squareradio.css';

function SquareRadio({
  disabled, value, label, name, image, onClick = () => { }, showFlag = true, checked,
}) {
  const squareRadioClasses = disabled ? 'squareRadio__container squareRadio__radioDisabled' : 'squareRadio__container';
  const squareRadioImageClasses = disabled ? 'squareRadio__flagImgDisabled ' : 'squareRadio__flagImg ';

  function handleRedirect(e) {
    setTimeout(() => {
      onClick(e);
    }, 250);
  }

  function handleRadioCheck() {
    const options = document.getElementsByName('option');
    for (let i = 0; i < options.length; i += 1) {
      const checkbox = options[i];
      const option = options[i].parentElement.parentElement;
      if (!checkbox.checked) {
        option.classList.remove('squareRadio__containerChecked');
      } else {
        option.classList.add('squareRadio__containerChecked');
      }
    }
  }

  return (
    <label role="presentation" className={squareRadioClasses}>
      <div className={showFlag ? 'squareRadio__labelFlag' : 'squareRadio__labelNoFlag'}>
        {
          showFlag && (<img src={image} className={squareRadioImageClasses} alt="UK flag" width={48} height={48} />)
        }
        <p className="squareRadio__label bold">{label}</p>
        <input
          className="squareRadio__circle"
          type="radio"
          name={name}
          disabled={disabled}
          onChange={handleRadioCheck}
          onClick={handleRedirect}
          checked={checked}
          value={value}
        />
      </div>
    </label>
  );
}

export default SquareRadio;

/* eslint-disable react/jsx-no-bind */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './symptom.css';
import { useLocation, useNavigate } from 'react-router-dom';
import i18n from 'i18next';
import Nav from '../../components/Nav/Nav';
import ColoredBar from '../../components/ColoredBar/ColoredBar';
import SquareRadio from '../../components/Radio/SquareRadio';

export default function Symptom() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { language } = i18n;
  const ageGroup = location.state?.ageGroup;

  useEffect(() => {
    if (ageGroup === undefined) navigate('/ageGroup');
  }, []);

  function handleRedirect(e) {
    const symptomValue = e.target.value;

    setTimeout(() => {
      if (symptomValue !== '') {
        navigate('/questions', {
          state: {
            language,
            ageGroup,
            symptom: symptomValue,
          },
        });
      }
    }, 250);
  }

  const otherSymptomRedirect = () => {
    setTimeout(() => {
      navigate('/otherSymptom');
    }, 250);
  };

  return (
    <div className="container">
      <Nav backArrow logo closeButton />
      <ColoredBar />
      <div className="containerContent symptom__content">
        <h3>{t('symptompage.h3')}</h3>
        <div className="symptom__buttons">
          <SquareRadio
            name="option"
            value="Cough"
            label={t('symptompage.option1')}
            showFlag={false}
            onClick={handleRedirect}
          />
          <SquareRadio
            name="option"
            value="Diarrhoea"
            label={t('symptompage.option2')}
            showFlag={false}
            onClick={handleRedirect}
          />
          <SquareRadio
            name="option"
            value="Fever"
            label={t('symptompage.option3')}
            showFlag={false}
            onClick={handleRedirect}
          />
          <SquareRadio
            name="option"
            value="Other"
            label={t('symptompage.option4')}
            showFlag={false}
            onClick={otherSymptomRedirect}
          />
        </div>
      </div>
    </div>
  );
}

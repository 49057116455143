/* eslint-disable react/jsx-no-bind, import/prefer-default-export */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import i18n from 'i18next';
import ColoredBar from '../../components/ColoredBar/ColoredBar';
import Nav from '../../components/Nav/Nav';
import ListRadio from '../../components/Radio/ListRadio';
import './agegroup.css';

export default function AgeGroup() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { language } = i18n;

  function handleNeonatalRedirect(e) {
    const ageValue = e.target.value;
    setTimeout(() => {
      if (ageValue !== '') {
        navigate('/questions', {
          state: {
            language,
            ageGroup: ageValue,
            symptom: 'Neonatal',
          },
        });
      }
    }, 250);
  }

  function handleRedirect(e) {
    const ageValue = e.target.value;
    setTimeout(() => {
      if (ageValue !== '') {
        navigate('/symptom', {
          state: {
            ageGroup: ageValue,
          },
        });
      }
    }, 250);
  }

  return (
    <div className="container">
      <Nav backArrow logo closeButton />
      <ColoredBar />
      <div className="containerContent agegroup__page__content">
        <h3 className="agegroup__page__content__h3">{t('agegrouppage.h3')}</h3>
        <div className="agegroup__options">
          <ListRadio name="option" value="1" label={t('agegrouppage.option1')} onChange={handleNeonatalRedirect} />
          <ListRadio name="option" value="2" label={t('agegrouppage.option2')} onChange={handleNeonatalRedirect} />
          <ListRadio name="option" value="3" label={t('agegrouppage.option3')} onChange={handleRedirect} />
          <ListRadio name="option" value="4" label={t('agegrouppage.option4')} onChange={handleRedirect} />
          <ListRadio name="option" value="5" label={t('agegrouppage.option5')} onChange={handleRedirect} />
          <ListRadio name="option" value="6" label={t('agegrouppage.option6')} onChange={handleRedirect} />
        </div>
      </div>
    </div>
  );
}

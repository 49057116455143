// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.finalMessage__content {
  justify-content: space-between;
  padding-bottom: var(--spacing-m);
}
.finalMessage__title {
  text-align: center;
  padding-bottom: var(--spacing-xs);
  color: var(--brand-colors-secondary-5);
}
.finalMessage__paragraph {
  text-align: center;
  color: var(--neutral-4);
}`, "",{"version":3,"sources":["webpack://./src/pages/FinalMessage/finalmessage.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,gCAAgC;AAClC;AACA;EACE,kBAAkB;EAClB,iCAAiC;EACjC,sCAAsC;AACxC;AACA;EACE,kBAAkB;EAClB,uBAAuB;AACzB","sourcesContent":[".finalMessage__content {\n  justify-content: space-between;\n  padding-bottom: var(--spacing-m);\n}\n.finalMessage__title {\n  text-align: center;\n  padding-bottom: var(--spacing-xs);\n  color: var(--brand-colors-secondary-5);\n}\n.finalMessage__paragraph {\n  text-align: center;\n  color: var(--neutral-4);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textarea__component {
  display: flex;
  flex-direction: column;
}
.textarea__component__label p{
  color: var(--neutral-5);
  margin: 0;
}
.textarea__component__textarea {
  height: 136px;
  padding: var(--spacing-xs) var(--spacing-m);
  border: var(--border-width-hairline) solid var(--brand-colors-primary-3);
  border-radius: var(--border-radius-default);
  margin: var(--spacing-xs) 0;

}
.textarea__component__textarea::placeholder {
  color: var(--neutral-3);
  font-size: var(--font-size-default);
}
.textarea__component__textarea:focus {
  outline: none !important;
  border: var(--border-width-thin) solid var(--brand-colors-primary-3);
  color: var(--neutral-4);
}
.textarea__component__textarea:disabled {
  border: var(--order-width-hairline) solid var(--neutral-2);
}
#lblRemainingCount {
  color: var(--neutral-3);
  text-align: end;
}

.small__error,
.textarea__component__label__error p{
  color: var(--feedback-colors-red) !important;
}

.textarea__component__textarea__error {
  outline: none;
  border: var(--border-width-hairline) solid var(--feedback-colors-red) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/TextArea/textarea.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,uBAAuB;EACvB,SAAS;AACX;AACA;EACE,aAAa;EACb,2CAA2C;EAC3C,wEAAwE;EACxE,2CAA2C;EAC3C,2BAA2B;;AAE7B;AACA;EACE,uBAAuB;EACvB,mCAAmC;AACrC;AACA;EACE,wBAAwB;EACxB,oEAAoE;EACpE,uBAAuB;AACzB;AACA;EACE,0DAA0D;AAC5D;AACA;EACE,uBAAuB;EACvB,eAAe;AACjB;;AAEA;;EAEE,4CAA4C;AAC9C;;AAEA;EACE,aAAa;EACb,gFAAgF;AAClF","sourcesContent":[".textarea__component {\n  display: flex;\n  flex-direction: column;\n}\n.textarea__component__label p{\n  color: var(--neutral-5);\n  margin: 0;\n}\n.textarea__component__textarea {\n  height: 136px;\n  padding: var(--spacing-xs) var(--spacing-m);\n  border: var(--border-width-hairline) solid var(--brand-colors-primary-3);\n  border-radius: var(--border-radius-default);\n  margin: var(--spacing-xs) 0;\n\n}\n.textarea__component__textarea::placeholder {\n  color: var(--neutral-3);\n  font-size: var(--font-size-default);\n}\n.textarea__component__textarea:focus {\n  outline: none !important;\n  border: var(--border-width-thin) solid var(--brand-colors-primary-3);\n  color: var(--neutral-4);\n}\n.textarea__component__textarea:disabled {\n  border: var(--order-width-hairline) solid var(--neutral-2);\n}\n#lblRemainingCount {\n  color: var(--neutral-3);\n  text-align: end;\n}\n\n.small__error,\n.textarea__component__label__error p{\n  color: var(--feedback-colors-red) !important;\n}\n\n.textarea__component__textarea__error {\n  outline: none;\n  border: var(--border-width-hairline) solid var(--feedback-colors-red) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

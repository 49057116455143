// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-div {
	background-color: transparent;
	border-radius: 4px;
}

.progress {
	background: var(--brand-colors-primary-3);
	height: 8px;
	transition: 0.5s ease;
	border-radius: 4px;
	margin: 0;
}

.progressComp h1 {
	font-size: 6px;
}

.percent-number {
	color: rgb(121, 121, 121);
}
`, "",{"version":3,"sources":["webpack://./src/components/ProgressBar/progressbar.css"],"names":[],"mappings":"AAAA;CACC,6BAA6B;CAC7B,kBAAkB;AACnB;;AAEA;CACC,yCAAyC;CACzC,WAAW;CACX,qBAAqB;CACrB,kBAAkB;CAClB,SAAS;AACV;;AAEA;CACC,cAAc;AACf;;AAEA;CACC,yBAAyB;AAC1B","sourcesContent":[".progress-div {\n\tbackground-color: transparent;\n\tborder-radius: 4px;\n}\n\n.progress {\n\tbackground: var(--brand-colors-primary-3);\n\theight: 8px;\n\ttransition: 0.5s ease;\n\tborder-radius: 4px;\n\tmargin: 0;\n}\n\n.progressComp h1 {\n\tfont-size: 6px;\n}\n\n.percent-number {\n\tcolor: rgb(121, 121, 121);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

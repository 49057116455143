/* eslint-disable react/jsx-no-bind, import/prefer-default-export */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './homepage.css';
import Nav from '../../components/Nav/Nav';
import arrow from '../../assets/designSystem/icons/arrow/right.svg';
import Button from '../../components/Button/Button';
import ColoredBar from '../../components/ColoredBar/ColoredBar';

export default function HomePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  function handleRedirect() {
    navigate('/loading', { state: { nextPage: '/disclaimer' } });
    // ReactGA.event({
    //   category: 'Button',
    //   action: 'Started triage',
    // });
  }
  return (
    <div className="container">
      <Nav homepage />
      <div className="containerContent home__content">
        <div>
          <h2>{t('homepage.homeh2')}</h2>
          <p className="home__content__p">
            {t('homepage.paragraph')}
          </p>
          <p className="home__content__p">{t('homepage.contentp')}</p>
        </div>
        <Button width="fullWidth" hierarchy="primary" type="button" onClick={handleRedirect} additionalClass=" start">
          <span>{t('homepage.button')}</span>
          <img src={arrow} height={16} alt="arrow-right" />
        </Button>
      </div>
      <ColoredBar big="bigColoredBar" />
    </div>
  );
}

/* eslint-disable react/jsx-no-bind */
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { AddFeedbackAPI } from '../../API/api';
import './feedback.css';
import Nav from '../../components/Nav/Nav';
import ColoredBar from '../../components/ColoredBar/ColoredBar';
import TextArea from '../../components/TextArea/TextArea';
import Button from '../../components/Button/Button';
import Rate from '../../components/Rating/Rating';

function Feedback() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [activebtn, setActivebtn] = useState(false);
  const [wordcount, setWordcount] = useState(0);
  const [textInput, settextInput] = useState('');
  const [rating, setRating] = useState(0);
  const mutationRef = useRef(rating);
  const AnswerLog = location.state?.answerlog;

  useEffect(() => {
    mutationRef.current = rating;

    if (mutationRef.current > 0) {
      setActivebtn(true);
    } else {
      setActivebtn(false);
    }
  }, [rating]);

  function handleChange(e) {
    setWordcount(e.target.value.length);
    settextInput(e.target.value);
  }
  const ratingChanged = newRating => {
    setRating(newRating);
  };

  async function sendFeedback() {
    const data = await AddFeedbackAPI(rating, textInput, AnswerLog);
    const response = await data.json();
    if (!data.ok) {
      navigate('/error', { state: { code: data.status, message: JSON.stringify(response) } });
    } else {
      navigate('/finalMessage', { state: { feedback: true } });
    }
  }

  function noFeedback() {
    navigate('/finalMessage', {
      state: {
        feedback: false,
      },
    });
  }

  return (
    <div className="container">
      <Nav logo closeButton />
      <ColoredBar />
      <div className="containerContent feedbackcontainer">
        <div className="feedbackcontent">
          <h3 className="feedbacktitle">{t('feedbackpage.h3')}</h3>
          <p className="feedbackp">{t('feedbackpage.p')}</p>
          <Rate onRating={ratingChanged} rating={rating} />
          <div className="feedback__textarea">
            <TextArea
              label={t('feedbackpage.textarealabel')}
              name="feedback"
              placeholder={t('feedbackpage.textareaplaceholder')}
              onChange={handleChange}
              wordcount={wordcount}
              value={textInput}
            />
          </div>
        </div>
        <div className="feedback__footer">
          <Button type="submit" disabled={!activebtn} onClick={sendFeedback} hierarchy="primary" width="fullWidth">
            {t('feedbackpage.button1')}
          </Button>
          <Button onClick={noFeedback} hierarchy="tertiary" width="fullWidth">{t('feedbackpage.button2')}</Button>
        </div>
      </div>
    </div>
  );
}

export default Feedback;

import React, { useState, useEffect } from 'react';
import './progressbar.css';

export default function ProgressBar({ width, percent, status }) {
  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(percent * width);
  });

  return (
    <div className="progressComp">
      <h1 className="percent-number">{status}</h1>
      <div className="progress-div" style={{ width }}>
        {value === 0 && (
          <div style={{ width: `${value}vw` }} className="jump" />
        )}
        {value > 0 && (
          <div style={{ width: `${value}vw` }} className="progress" />
        )}
      </div>
    </div>
  );
}

/* eslint-disable react/jsx-no-bind, import/prefer-default-export */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import './finalmessage.css';
import Nav from '../../components/Nav/Nav';
import ColoredBar from '../../components/ColoredBar/ColoredBar';
import Button from '../../components/Button/Button';

export default function FinalMessage() {
  const { t } = useTranslation();
  const location = useLocation();
  const feedback = location.state?.feedback;
  const navigate = useNavigate();

  function handleRedirect() {
    navigate('/homepage');
  }

  return (
    <div className="container">
      <Nav logo closeButton />
      <ColoredBar />
      <div className="containerContent finalMessage__content">
        <div>
          <h3 className="finalMessage__title">{t('finalmessagepage.h3')}</h3>
          {
            feedback ? <p className="finalMessage__paragraph">{t('finalmessagepage.yesfeedback')}</p>
              : <p className="finalMessage__paragraph">{t('finalmessagepage.nofeedback')}</p>
          }

        </div>
        <Button width="fullWidth" class="finalMessage__button" hierarchy="primary" onClick={handleRedirect}>
          {t('finalmessagepage.homebtn')}
        </Button>
      </div>
    </div>
  );
}

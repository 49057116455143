// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.25);
}

.modal__popup {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 95vh;
  overflow: scroll;
  background-color: var(--neutral-1);
  border-radius: var(--border-radius-default) var(--border-radius-default) 0 0;
  z-index: 30;
  animation: slide-down 300ms ease-out forwards;
}

.modal__content {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: var(--spacing-s);
  gap: var(--spacing-l);
}

.modal__closeBtn {
  width: 26px;
  align-self: flex-end;
}

.modal__header {
  color: var(--brand-colors-secondary-5);
}

.modal__paragraph {
  color: var(--neutral-4);
}

.modal__containerButtons {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/modal.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,aAAa;EACb,WAAW;EACX,qCAAqC;AACvC;;AAEA;EACE,eAAe;EACf,SAAS;EACT,OAAO;EACP,WAAW;EACX,gBAAgB;EAChB,gBAAgB;EAChB,kCAAkC;EAClC,4EAA4E;EAC5E,WAAW;EACX,6CAA6C;AAC/C;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,yBAAyB;EACzB,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,oBAAoB;AACtB;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA;EACE;IACE,UAAU;IACV,2BAA2B;EAC7B;EACA;IACE,UAAU;IACV,wBAAwB;EAC1B;AACF","sourcesContent":[".modal__backdrop {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100vh;\n  z-index: 20;\n  background-color: rgba(0, 0, 0, 0.25);\n}\n\n.modal__popup {\n  position: fixed;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  max-height: 95vh;\n  overflow: scroll;\n  background-color: var(--neutral-1);\n  border-radius: var(--border-radius-default) var(--border-radius-default) 0 0;\n  z-index: 30;\n  animation: slide-down 300ms ease-out forwards;\n}\n\n.modal__content {\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n  padding: var(--spacing-s);\n  gap: var(--spacing-l);\n}\n\n.modal__closeBtn {\n  width: 26px;\n  align-self: flex-end;\n}\n\n.modal__header {\n  color: var(--brand-colors-secondary-5);\n}\n\n.modal__paragraph {\n  color: var(--neutral-4);\n}\n\n.modal__containerButtons {\n  display: flex;\n  flex-direction: column;\n  gap: var(--spacing-s);\n}\n\n@keyframes slide-down {\n  from {\n    opacity: 0;\n    transform: translateY(3rem);\n  }\n  to {\n    opacity: 1;\n    transform: translateY(0);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import './button.css';

function Button({
  children, width, hierarchy, additionalClass, disabled, icon, onClick, prefix, suffix,
}) {
  let classes = '';

  if (width === 'fullWidth') {
    classes += ' fullWidth';
  } else if (width === 'smallWidth') {
    classes += ' smallWidth small-button';
  } else if (width === 'defaultWidth') {
    classes += ' defaultWidth';
  }

  if (hierarchy === 'primary') {
    classes += ' primary';
  } else if (hierarchy === 'secondary') {
    classes += ' secondary';
  } else if (hierarchy === 'tertiary') {
    classes += ' tertiary';
  }

  if (icon) {
    classes += ' icon';
  }

  if (additionalClass) {
    classes += additionalClass;
  }

  return (
    <button type="button" disabled={disabled} className={classes} onClick={onClick}>
      { prefix && (<img src={prefix} alt="prefix" className="prefixImg" />)}
      {children}
      { suffix && (<img src={suffix} alt="suffix" className="suffixImg" />)}
    </button>
  );
}

export default Button;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terms__titles {
    margin-top: 32px;
    margin-bottom: 8px;
}
ol > li::marker {
    font-weight: var(--font-weight-md);
  }`, "",{"version":3,"sources":["webpack://./src/pages/TermsAndConditions/termsandconditions.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,kCAAkC;EACpC","sourcesContent":[".terms__titles {\n    margin-top: 32px;\n    margin-bottom: 8px;\n}\nol > li::marker {\n    font-weight: var(--font-weight-md);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import { useTranslation } from 'react-i18next';
import closeButtonPic from '../../../assets/designSystem/icons/close.svg';
import Button from '../../../components/Button/Button';
import './popup.css';

export default function cough({ babyOrChild, callback }) {
  const { t } = useTranslation();
  return (
    <>
      <div className="modal__backdrop" />
      <div className="modal__popup">
        <div className="popup__container">
          <img
            src={closeButtonPic}
            alt="close button"
            className="popup__closeBtn"
            role="presentation"
            width="24"
            onClick={callback}
          />
          <div className="popup__content">
            <h4 className="popup__title">{t('popUps.cough.title')}</h4>
            <ul className="popup__ul">
              <li>
                {t(`popUps.cough.${babyOrChild}.li1`)}
                {' '}
              </li>
              <li>{t(`popUps.cough.${babyOrChild}.li2`)}</li>
              <li>{t(`popUps.cough.${babyOrChild}.li3`)}</li>
            </ul>
          </div>
          <div className="popup__button">
            <Button width="fullWidth" hierarchy="primary" onClick={callback}>
              {t('popUps.button')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

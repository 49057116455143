// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.coloredBar {
  height: 2px;
  min-height: 2px;
  background: linear-gradient(to right, var(--brand-colors-primary-3) 0 25%, var(--feedback-colors-yellow) 25% 50%, var(--feedback-colors-orange) 50% 75%, var(--feedback-colors-red) 75% 100%);
}

.bigColoredBar {
  height: 32px;
  min-height: 32px;
}`, "",{"version":3,"sources":["webpack://./src/components/ColoredBar/coloredBar.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,eAAe;EACf,6LAA6L;AAC/L;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".coloredBar {\n  height: 2px;\n  min-height: 2px;\n  background: linear-gradient(to right, var(--brand-colors-primary-3) 0 25%, var(--feedback-colors-yellow) 25% 50%, var(--feedback-colors-orange) 50% 75%, var(--feedback-colors-red) 75% 100%);\n}\n\n.bigColoredBar {\n  height: 32px;\n  min-height: 32px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

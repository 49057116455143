// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home__content {
  margin-bottom: var(--spacing-m);
  justify-content: space-between;
}
.home__content__p {
  color: var(--brand-colors-secondary-5);
  margin-top: var(--spacing-xs);
  margin-bottom: var(--spacing-xs);
}
.home__content p span:nth-child(2) {
  display: none;
}
.home__content>button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.home__content > button > img{
  margin-left: var(--spacing-xs);
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(61deg) brightness(105%) contrast(101%);
}
/* colored bar */
.home__colors {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: var(--spacing-l);
  bottom: 0;
}
.yellow {
  background-color: #FFC730;
  width: 25%;
}
.green {
  background-color: #14C7C4;
  width: 25%;
}
.red {
  background-color: #FF2B26;
  width: 25%;
}
.orange {
  background-color: #FF8A00;
  width: 25%;
}
@media screen and (min-width: 360px) {
  .home__content p span:nth-child(1){
    display: none;
  }
  .home__content p span:nth-child(2) {
    display: inline-flex;
    margin-left: var(--spacing-xxs);
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/HomePage/homepage.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,8BAA8B;AAChC;AACA;EACE,sCAAsC;EACtC,6BAA6B;EAC7B,gCAAgC;AAClC;AACA;EACE,aAAa;AACf;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,8BAA8B;EAC9B,6FAA6F;AAC/F;AACA,gBAAgB;AAChB;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,wBAAwB;EACxB,SAAS;AACX;AACA;EACE,yBAAyB;EACzB,UAAU;AACZ;AACA;EACE,yBAAyB;EACzB,UAAU;AACZ;AACA;EACE,yBAAyB;EACzB,UAAU;AACZ;AACA;EACE,yBAAyB;EACzB,UAAU;AACZ;AACA;EACE;IACE,aAAa;EACf;EACA;IACE,oBAAoB;IACpB,+BAA+B;EACjC;AACF","sourcesContent":[".home__content {\n  margin-bottom: var(--spacing-m);\n  justify-content: space-between;\n}\n.home__content__p {\n  color: var(--brand-colors-secondary-5);\n  margin-top: var(--spacing-xs);\n  margin-bottom: var(--spacing-xs);\n}\n.home__content p span:nth-child(2) {\n  display: none;\n}\n.home__content>button {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n}\n.home__content > button > img{\n  margin-left: var(--spacing-xs);\n  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(61deg) brightness(105%) contrast(101%);\n}\n/* colored bar */\n.home__colors {\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  height: var(--spacing-l);\n  bottom: 0;\n}\n.yellow {\n  background-color: #FFC730;\n  width: 25%;\n}\n.green {\n  background-color: #14C7C4;\n  width: 25%;\n}\n.red {\n  background-color: #FF2B26;\n  width: 25%;\n}\n.orange {\n  background-color: #FF8A00;\n  width: 25%;\n}\n@media screen and (min-width: 360px) {\n  .home__content p span:nth-child(1){\n    display: none;\n  }\n  .home__content p span:nth-child(2) {\n    display: inline-flex;\n    margin-left: var(--spacing-xxs);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

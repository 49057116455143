// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wdim__fullWidth {
	height: 90vh;
}

/* Same classes as popup.css with only one change.*/
.wdim__container {
	position: relative;
	padding: var(--spacing-s) var(--spacing-s) 0;
	display: flex;
	flex-direction: column;
	gap: var(--spacing-m);
	flex-grow: 1;
}

.wdim__content {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-l);
	flex-grow: 1;
}

.wdim__closeBtn {
	align-self: flex-end;
}

.wdim__button {
	position: sticky;
	bottom: 0;
	width: calc(100vw - var(--spacing-l));
	padding-top: var(--spacing-l);
	padding-bottom: var(--spacing-m);
	background: linear-gradient(180deg, rgba(250, 252, 253, 0) 0%, #FAFCFD 22.22%, #FAFCFD 100%);
}`, "",{"version":3,"sources":["webpack://./src/pages/Wdim/wdim.css"],"names":[],"mappings":"AAAA;CACC,YAAY;AACb;;AAEA,mDAAmD;AACnD;CACC,kBAAkB;CAClB,4CAA4C;CAC5C,aAAa;CACb,sBAAsB;CACtB,qBAAqB;CACrB,YAAY;AACb;;AAEA;CACC,aAAa;CACb,sBAAsB;CACtB,qBAAqB;CACrB,YAAY;AACb;;AAEA;CACC,oBAAoB;AACrB;;AAEA;CACC,gBAAgB;CAChB,SAAS;CACT,qCAAqC;CACrC,6BAA6B;CAC7B,gCAAgC;CAChC,4FAA4F;AAC7F","sourcesContent":[".wdim__fullWidth {\n\theight: 90vh;\n}\n\n/* Same classes as popup.css with only one change.*/\n.wdim__container {\n\tposition: relative;\n\tpadding: var(--spacing-s) var(--spacing-s) 0;\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: var(--spacing-m);\n\tflex-grow: 1;\n}\n\n.wdim__content {\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: var(--spacing-l);\n\tflex-grow: 1;\n}\n\n.wdim__closeBtn {\n\talign-self: flex-end;\n}\n\n.wdim__button {\n\tposition: sticky;\n\tbottom: 0;\n\twidth: calc(100vw - var(--spacing-l));\n\tpadding-top: var(--spacing-l);\n\tpadding-bottom: var(--spacing-m);\n\tbackground: linear-gradient(180deg, rgba(250, 252, 253, 0) 0%, #FAFCFD 22.22%, #FAFCFD 100%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Nav from '../../components/Nav/Nav';
import ColoredBar from '../../components/ColoredBar/ColoredBar';
import Button from '../../components/Button/Button';
import { AddClinicalFeedbackAPI } from '../../API/api';
import './clinicalfeedback.css';

function ClinicalFeedback() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { researchId } = useParams();
  const [isDisabled, setIsDisabled] = useState(true);
  const [questions, setQuestions] = useState({
    [t('clinicalFeedback.firstquestion')]: '',
    [t('clinicalFeedback.secondquestion')]: '',
    [t('clinicalFeedback.thirdquestion')]: '',
    [t('clinicalFeedback.fourthquestion')]: '',
  });

  useEffect(() => {
    const allAnswered = Object.values(questions).every(answer => answer !== '');
    setIsDisabled(!allAnswered);
  }, [questions]);

  const onChangeInput = e => {
    setQuestions({ ...questions, [e.target.name]: e.target.value });
  };

  const submitAnswers = async data => {
    const questionsAndAnswersArr = Object.entries(data).map(([question, answer]) => ({ question, answer }));
    const rowResponse = await AddClinicalFeedbackAPI(researchId, questionsAndAnswersArr);
    const response = await rowResponse.json();
    if (!rowResponse.ok) {
      navigate('/error', { state: { code: rowResponse.status, message: JSON.stringify(response) } });
    } else {
      navigate('/finalMessage', { state: { feedback: true } });
    }
  };

  const handleDismissBtn = () => {
    navigate('/homepage');
  };

  return (
    <div className="container">
      <Nav logo closeButton />
      <ColoredBar />
      <div className="containerContent ClinicalFeedback__container">
        <div className="ClinicalFeedback__questionnaire-container">
          <h3>{t('clinicalFeedback.title')}</h3>
          <div className="ClinicalFeedback__questions-container">
            {Object.keys(questions).map(question => (
              <div key={question} className="ClinicalFeedback__question-container">
                <p className="ClinicalFeedback__question-title bold">{question}</p>
                <div className="ClinicalFeedback__answer-options">
                  <label htmlFor="Yes" className="ClinicalFeedback__answer-label">
                    {t('clinicalFeedback.yes')}
                    <input
                      type="radio"
                      id="Yes"
                      value="Yes"
                      name={question}
                      className="ClinicalFeedback__answer-input"
                      onChange={onChangeInput}
                    />
                  </label>
                  <label htmlFor="No" className="ClinicalFeedback__answer-label">
                    {t('clinicalFeedback.no')}
                    <input
                      type="radio"
                      id="No"
                      value="No"
                      name={question}
                      className="ClinicalFeedback__answer-input"
                      onChange={onChangeInput}
                    />
                  </label>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="ClinicalFeedback__buttons-container">
          <Button
            width="fullWidth"
            hierarchy="primary"
            onClick={() => submitAnswers(questions)}
            disabled={isDisabled}
          >
            {t('clinicalFeedback.submit')}
          </Button>
          <Button width="fullWidth" hierarchy="tertiary" onClick={handleDismissBtn}>
            {t('clinicalFeedback.dismiss')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ClinicalFeedback;

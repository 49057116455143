import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ColoredBar from '../../components/ColoredBar/ColoredBar';
import Nav from '../../components/Nav/Nav';
import rightArrow from '../../assets/designSystem/icons/simple-arrow/right.svg';
import './menu.css';

function Menu() {
  const { t } = useTranslation();
  return (
    <div className="container">
      <Nav backArrow label="Settings & Info" />
      <ColoredBar />
      <div className="containerContent menu__container">
        <ul className="menu__ul">
          <li>
            <Link to="/language" className="menu__itemContainer">
              <p className="bold">{t('menu.lang')}</p>
              <img src={rightArrow} alt="logo" />
            </Link>
          </li>
          <li>
            <Link to="/terms" className="menu__itemContainer">
              <p className="bold">{t('menu.tc')}</p>
              <img src={rightArrow} alt="logo" />
            </Link>
          </li>
          <li>
            <Link to="/policy" className="menu__itemContainer">
              <p className="bold">{t('menu.privacy')}</p>
              <img src={rightArrow} alt="logo" />
            </Link>
          </li>
          <li>
            <a href="https://www.kitohealth.org" target="_blank" rel="noreferrer" className="menu__itemContainer">
              <p className="bold">{t('menu.website')}</p>
              <img src={rightArrow} alt="logo" />
            </a>
          </li>
          <li>
            <Link to="/feedback" className="menu__itemContainer">
              <p className="bold">{t('menu.feedback')}</p>
              <img src={rightArrow} alt="logo" />
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Menu;

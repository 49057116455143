// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.otherSymptom__content {
  padding: var(--spacing-l) var(--spacing-s) 0;
}

.otherSymptom__paragraph {
  margin: 0;
}

.otherSymptom__h3 {
  margin: 32px 0 8px;
}

.otherSymptom__input {
  border: var(--border-width-hairline) solid var(--brand-colors-primary-3);
  border-radius: var(--border-radius-l);
  padding: 8px 24px;
  margin: 24px 0;
}

.otherSymptom__input:focus {
  outline: var(--border-width-hairline) solid var(--brand-colors-primary-3);
}`, "",{"version":3,"sources":["webpack://./src/pages/OtherSymptom/otherSymptom.css"],"names":[],"mappings":"AAAA;EACE,4CAA4C;AAC9C;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,wEAAwE;EACxE,qCAAqC;EACrC,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,yEAAyE;AAC3E","sourcesContent":[".otherSymptom__content {\n  padding: var(--spacing-l) var(--spacing-s) 0;\n}\n\n.otherSymptom__paragraph {\n  margin: 0;\n}\n\n.otherSymptom__h3 {\n  margin: 32px 0 8px;\n}\n\n.otherSymptom__input {\n  border: var(--border-width-hairline) solid var(--brand-colors-primary-3);\n  border-radius: var(--border-radius-l);\n  padding: 8px 24px;\n  margin: 24px 0;\n}\n\n.otherSymptom__input:focus {\n  outline: var(--border-width-hairline) solid var(--brand-colors-primary-3);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

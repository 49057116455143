/* eslint-disable import/prefer-default-export, react/jsx-no-bind */
import React from 'react';
import { useTranslation } from 'react-i18next';
import './emergency.css';
import { useNavigate } from 'react-router-dom';
import Nav from '../../components/Nav/Nav';
import Button from '../../components/Button/Button';
import ColoredBar from '../../components/ColoredBar/ColoredBar';

export default function Emergency() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  function handleContinue() {
    navigate('/ageGroup');
  }

  return (
    <div className="container">
      <Nav backArrow logo closeButton />
      <ColoredBar />
      <div className="containerContent emergency__page">
        <p>{t('emergencypage.p1')}</p>
        <br />
        <p>{t('emergencypage.p2')}</p>
        <br />
        <p>{t('emergencypage.p3')}</p>
        <ul>
          <li><p>{t('emergencypage.li1')}</p></li>
          <li><p>{t('emergencypage.li2')}</p></li>
          <li><p>{t('emergencypage.li3')}</p></li>
        </ul>
        <div className="emergency__bottomwrapper">
          <Button width="fullWidth" hierarchy="primary" onClick={handleContinue} additionalClass=" agree">
            {t('emergencypage.button1')}
          </Button>
          <a className="emergency__callBtn" href="tel:0800721316">{t('emergencypage.button2')}</a>
        </div>
      </div>
    </div>
  );
}

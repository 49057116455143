import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import ColoredBar from '../../components/ColoredBar/ColoredBar';
import Nav from '../../components/Nav/Nav';
import './agreement.css';

function Agreement() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(true);
  const checkbox1 = useRef();
  const checkbox2 = useRef();
  const checkbox3 = useRef();

  const changeCheckboxHandler = () => {
    if (checkbox1.current.checked && checkbox2.current.checked && checkbox3.current.checked) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  const redirectHandler = () => {
    navigate('/homepage');
  };

  return (
    <div className="container">
      <Nav label={t('agreementpage.title')} />
      <ColoredBar />
      <div className="containerContent agreement__container">
        <p>{t('agreementpage.paragraph')}</p>
        <br />
        <label htmlFor="term1" className="agreement__checkboxLabel">
          <input
            type="checkbox"
            id="term1"
            className="agreement__checkboxInput"
            ref={checkbox1}
            onChange={changeCheckboxHandler}
          />
          <span>
            {t('agreementpage.checkbox1')}
            <Link to="/terms">{t('agreementpage.link1')}</Link>
            {t('agreementpage.extra1')}
          </span>
        </label>
        <label htmlFor="term2" className="agreement__checkboxLabel">
          <input
            type="checkbox"
            id="term2"
            className="agreement__checkboxInput"
            ref={checkbox2}
            onChange={changeCheckboxHandler}
          />
          <span>
            {t('agreementpage.checkbox2')}
            <Link to="/policy">{t('agreementpage.link2')}</Link>
            {t('agreementpage.extra1')}
          </span>
        </label>
        <label htmlFor="term3" className="agreement__checkboxLabel">
          <input
            type="checkbox"
            id="term3"
            className="agreement__checkboxInput"
            ref={checkbox3}
            onChange={changeCheckboxHandler}
          />
          {t('agreementpage.checkbox3')}
        </label>
        <div className="agreement__continueBtn">
          <Button
            width="fullWidth"
            hierarchy="primary"
            onClick={redirectHandler}
            disabled={isDisabled}
            additionalClass=" continue"
          >
            {t('continue')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Agreement;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.questionContent {
	justify-content: space-between;
	padding-bottom: var(--spacing-m);
	overflow: hidden;
}

.questions__progress-bar {
	margin: var(--spacing-m) var(--spacing-s);
}`, "",{"version":3,"sources":["webpack://./src/pages/Questions/questions.css"],"names":[],"mappings":"AAAA;CACC,8BAA8B;CAC9B,gCAAgC;CAChC,gBAAgB;AACjB;;AAEA;CACC,yCAAyC;AAC1C","sourcesContent":[".questionContent {\n\tjustify-content: space-between;\n\tpadding-bottom: var(--spacing-m);\n\toverflow: hidden;\n}\n\n.questions__progress-bar {\n\tmargin: var(--spacing-m) var(--spacing-s);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import down from '../../assets/designSystem/icons/chevron-circle/down.svg';
import up from '../../assets/designSystem/icons/chevron-circle/up.svg';
import imagesArray from '../../assets/designSystem/imagesIndex';
import './outputsection.css';

function OutputSection({ data, callback }) {
  const [isActive, setIsActive] = useState(false);
  const [mapObj, setMapObj] = useState({});

  function extractInnerHtmlFromUnderlineTag(htmlString) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;
    const underlineTag = tempDiv.querySelector('u');
    const extractedHtml = underlineTag !== null ? underlineTag.innerHTML : htmlString;
    return extractedHtml;
  }

  function replaceAnchorTagsWithSpans(sentence) {
    const anchorRegExp = /<a[\s]+([^>]+)>((?:.(?!<\/a>))*.)<\/a>/g;
    // eslint-disable-next-line arrow-body-style
    const newSentence = sentence.replace(anchorRegExp, (match, href, innerHTML) => {
      return `<span className="popup_text" name=${mapObj[innerHTML]}
       word=${extractInnerHtmlFromUnderlineTag(innerHTML)}>${extractInnerHtmlFromUnderlineTag(innerHTML)}</span>`;
    });
    return newSentence;
  }

  useEffect(() => {
    if (data.popupsMap) {
      setMapObj(data.popupsMap);
    }
  }, [data]);

  function renderDescription() {
    if (data.popupsMap) {
      return (
        <div className="outputsection__content">
          {parse(
            replaceAnchorTagsWithSpans(data.description),
            {
              replace: ({ attribs }) => {
                if (attribs && attribs.name && attribs.word) {
                  return (
                    <span className="popup_text" onClick={() => callback(attribs.name.toLowerCase())}>
                      {attribs.word}
                    </span>
                  );
                }
              },
            },
          )}
        </div>
      );
    }
    return (
      <div className="outputsection__content">
        {
          parse(data.description, {
            replace: ({ attribs }) => {
              if (attribs && attribs.name && attribs.word) {
                return (
                  <span
                    className="popup_text"
                    onClick={() => callback(attribs.name.toLowerCase())}
                  >
                    {attribs.word}
                  </span>
                );
              }
            },
          })
        }
      </div>
    );
  }

  return (
    <div className="outputsection">
      <div role="presentation" className="outputsection__title" onClick={() => setIsActive(!isActive)}>
        <div>{isActive ? <img src={up} alt="down" /> : <img src={down} alt="down" />}</div>
        <h4>{data.title}</h4>
      </div>
      {isActive
        && (
          <div className="outputsection-content">
            {
              data.icon && (
                <div className="outputsection-content-img">
                  <img src={imagesArray[data.icon]} alt="img" className="outputsection-raffa" />
                </div>
              )
            }
            {renderDescription()}
          </div>
        )}
    </div>
  );
}
export default OutputSection;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.disclaimer__page {
  position: relative;
  margin-bottom: 120px;
}
.disclaimer__bottomwrapper{
  position: fixed;
  bottom: 0;
  width: calc(100vw - var(--spacing-l));
  padding-top: var(--spacing-l);
  padding-bottom: var(--spacing-m);
  background: linear-gradient(180deg, rgba(250, 252, 253, 0) 0%, #FAFCFD 22.22%, #FAFCFD 100%);
}
.disclaimer__bottomwrapper button:nth-child(2){
  margin-top: var(--spacing-xs);
}`, "",{"version":3,"sources":["webpack://./src/pages/Disclaimer/disclaimer.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,oBAAoB;AACtB;AACA;EACE,eAAe;EACf,SAAS;EACT,qCAAqC;EACrC,6BAA6B;EAC7B,gCAAgC;EAChC,4FAA4F;AAC9F;AACA;EACE,6BAA6B;AAC/B","sourcesContent":[".disclaimer__page {\n  position: relative;\n  margin-bottom: 120px;\n}\n.disclaimer__bottomwrapper{\n  position: fixed;\n  bottom: 0;\n  width: calc(100vw - var(--spacing-l));\n  padding-top: var(--spacing-l);\n  padding-bottom: var(--spacing-m);\n  background: linear-gradient(180deg, rgba(250, 252, 253, 0) 0%, #FAFCFD 22.22%, #FAFCFD 100%);\n}\n.disclaimer__bottomwrapper button:nth-child(2){\n  margin-top: var(--spacing-xs);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

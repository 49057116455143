// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.errorHandler__content {
  justify-content: space-between;
  padding-bottom: var(--spacing-m);
}

.errorHandler__innerContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-l);
}

.errorHandler__textContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: var(--spacing-xs);
}

.errorHandler__smallText {
  color: var(--neutral-3);
}`, "",{"version":3,"sources":["webpack://./src/pages/ErrorHandler/errorhandler.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,gCAAgC;AAClC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".errorHandler__content {\n  justify-content: space-between;\n  padding-bottom: var(--spacing-m);\n}\n\n.errorHandler__innerContent {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: var(--spacing-l);\n}\n\n.errorHandler__textContent {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n  gap: var(--spacing-xs);\n}\n\n.errorHandler__smallText {\n  color: var(--neutral-3);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

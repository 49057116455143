import baby from './icons/baby.svg';
import hospital from './icons/hospital.svg';
import babyBottle from './icons/baby-bottle.svg';
import waterBottle from './icons/water-bottle.svg';
import salineSolution from './icons/saline-solution.svg';
import tea from './icons/tea.svg';
import care from './icons/care.svg';
import nappies from './icons/nappies.svg';
import toilet from './icons/toilet.svg';
import child from './icons/child.svg';
import driving from './icons/driving.svg';
import safe from './icons/safe.svg';
import virus from './icons/virus.svg';
import ambulance from './icons/ambulance.svg';

export default {
  waterBottle,
  babyBottle,
  salineSolution,
  tea,
  care,
  nappies,
  toilet,
  baby,
  child,
  hospital,
  driving,
  safe,
  virus,
  ambulance,
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import closeButtonPic from '../../../assets/designSystem/icons/close.svg';
import Button from '../../../components/Button/Button';
import './popup.css';

export default function eye({ babyOrChild, callback }) {
  const { t } = useTranslation();
  return (
    <>
      <div className="modal__backdrop" />
      <div className="modal__popup">
        <div className="popup__container">
          <img
            src={closeButtonPic}
            alt="close button"
            className="popup__closeBtn"
            role="presentation"
            width="24"
            onClick={callback}
          />
          <div className="popup__content">
            <h4 className="popup__title">{t(`popUps.eye.${babyOrChild}.title`)}</h4>
            <p>{t(`popUps.eye.${babyOrChild}.p1`)}</p>
            <p>{t(`popUps.eye.${babyOrChild}.p2`)}</p>
            <p>{t('popUps.eye.p3')}</p>
            <p>{t(`popUps.eye.${babyOrChild}.p4`)}</p>
          </div>
          <div className="popup__button">
            <Button width="fullWidth" hierarchy="primary" onClick={callback}>{t('popUps.button')}</Button>
          </div>
        </div>
      </div>
    </>
  );
}

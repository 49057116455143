import React from 'react';
import './coloredBar.css';

function ColoredBar({ big }) {
  let barClasses = 'coloredBar';
  if (big) {
    barClasses += ' bigColoredBar';
  }

  return (
    <div className={barClasses} />
  );
}

export default ColoredBar;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.questionCard__container {
  position: relative;
  right: -1000px;
  animation: slide 0.5s forwards;
}

@keyframes slide {
  100% { right: 0; }
}

.questionCard__titleContainer {
  text-align: center;
}

.questionCard__options {
  margin-top: 32px;
  border-radius: var(--border-radius-default);
  box-shadow: var(--box-shadow);
}

.questionCard__options > div:first-child > .checkbox__component label {
  border-top: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/QuestionCard/questioncard.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,cAAc;EAEd,8BAA8B;AAChC;;AAMA;EACE,OAAO,QAAQ,EAAE;AACnB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,2CAA2C;EAC3C,6BAA6B;AAC/B;;AAEA;EACE,aAAa;AACf","sourcesContent":[".questionCard__container {\n  position: relative;\n  right: -1000px;\n  -webkit-animation: slide 0.5s forwards;\n  animation: slide 0.5s forwards;\n}\n\n@-webkit-keyframes slide {\n  100% { right: 0; }\n}\n\n@keyframes slide {\n  100% { right: 0; }\n}\n\n.questionCard__titleContainer {\n  text-align: center;\n}\n\n.questionCard__options {\n  margin-top: 32px;\n  border-radius: var(--border-radius-default);\n  box-shadow: var(--box-shadow);\n}\n\n.questionCard__options > div:first-child > .checkbox__component label {\n  border-top: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useMemo, useState } from 'react';
import filledStar from '../../assets/designSystem/icons/star/filled.svg';
import unfilledStar from '../../assets/designSystem/icons/star/outline.svg';
import './rating.css';

function Rate(
  {
    count, rating, onRating,
  },
) {
  const [hoverRating, setHoverRating] = useState(0);

  const getSrc = index => {
    if (hoverRating >= index) {
      return filledStar;
    }
    if (!hoverRating && rating >= index) {
      return filledStar;
    }

    return unfilledStar;
  };

  const starRating = useMemo(() => Array(count)
    .fill(0)
    .map((_, i) => i + 1)
    .map(idx => {
      const src = getSrc(idx);
      return (
        <input
          type="image"
          key={idx}
          src={src}
          alt="star"
          onClick={() => onRating(idx)}
          onMouseEnter={() => setHoverRating(idx)}
          onMouseLeave={() => setHoverRating(0)}
        />
      );
    }), [count, rating, hoverRating]);

  return <div className="rating__starContainer">{starRating}</div>;
}

Rate.defaultProps = {
  count: 5,
  rating: 0,
};

export default Rate;

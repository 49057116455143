// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.agegroup__page__content {
  height: calc(100vh - 58px);
  overflow: scroll;
}
.agegroup__page__content__h3 {
  text-align: center;
}
.agegroup__options {
  border-radius: var(--border-radius-m);
  box-shadow: var(--box-shadow);
  margin-top: var(--spacing-l);
  margin-bottom: var(--spacing-m);
}
`, "",{"version":3,"sources":["webpack://./src/pages/AgeGroup/agegroup.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,gBAAgB;AAClB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,qCAAqC;EACrC,6BAA6B;EAC7B,4BAA4B;EAC5B,+BAA+B;AACjC","sourcesContent":[".agegroup__page__content {\n  height: calc(100vh - 58px);\n  overflow: scroll;\n}\n.agegroup__page__content__h3 {\n  text-align: center;\n}\n.agegroup__options {\n  border-radius: var(--border-radius-m);\n  box-shadow: var(--box-shadow);\n  margin-top: var(--spacing-l);\n  margin-bottom: var(--spacing-m);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

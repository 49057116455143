/* eslint-disable import/prefer-default-export */
import React, { useEffect } from 'react';
import './Splashscreen.css';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/designSystem/logo/logo-white2.svg';

export default function Splashscreen() {
  const navigate = useNavigate();
  function handleRedirect() {
    navigate('/language');
  }

  useEffect(() => {
    setTimeout(() => {
      handleRedirect();
    }, 2000);
  }, []);

  return (
    <div className="container splash">
      <div className="splash__svgs">
        <img height={40} src={logo} alt="kito logo" />
      </div>
    </div>
  );
}

/* eslint-disable max-len */
import React from 'react';
import './textarea.css';

function TextArea({
  disabled, name, label, placeholder, value, hasError, onChange, wordcount,
}) {
  return (
    <div style={disabled ? {
      pointerEvents: 'none', opacity: '0.4', display: 'flex', flexDirection: 'column',
    } : { display: 'flex', flexDirection: 'column' }}
    >
      <label htmlFor={name} className={hasError ? 'textarea__component__label textarea__component__label__error' : 'textarea__component__label'}><p className="bold">{label}</p></label>
      <textarea
        className={hasError ? 'textarea__component__textarea textarea__component__textarea__error' : 'textarea__component__textarea'}
        maxLength="100"
        disabled={disabled}
        value={value}
        onChange={onChange}
        name={name}
        placeholder={placeholder}
      />
      <small id="lblRemainingCount" className={hasError ? 'small small__error' : 'small'}>{`${wordcount}/100`}</small>
    </div>
  );
}

export default TextArea;

/* eslint-disable no-mixed-operators */
/* eslint-disable react/jsx-no-bind, import/prefer-default-export */
import React, { useState } from 'react';
import './language.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SquareRadio from '../../components/Radio/SquareRadio';
import En from '../../assets/designSystem/icons/United Kingdom.svg';
import Swa from '../../assets/designSystem/icons/Kenya.svg';
import Button from '../../components/Button/Button';

export default function Language() {
  const navigate = useNavigate();
  const [activebtn, setActivebtn] = useState(true);
  const { i18n, t } = useTranslation();

  function handleRedirect() {
    navigate('/onboarding');
  }

  function handleClick(e) {
    if (e.target.value !== '') {
      setActivebtn(false);
      i18n.changeLanguage(e.target.value);
    }
  }

  return (
    <div className="container">
      <div className="containerContent language__page">
        <div>
          <h3>{t('languagepage.start')}</h3>
          <p className="language__paragraph">{t('languagepage.selectlang')}</p>
          <div id="test" className="language__radios">
            <SquareRadio
              name="option"
              image={En}
              label="English"
              value="English"
              onClick={handleClick}
            />
            <SquareRadio
              name="option"
              image={Swa}
              label="Swahili"
              value="Swahili"
              onClick={handleClick}
            />
          </div>
        </div>
        <div className="language__page__button">
          <Button
            hierarchy="primary"
            width="fullWidth"
            disabled={activebtn}
            onClick={handleRedirect}
            additionalClass=" continue"
          >
            {t('continue')}
          </Button>
        </div>
      </div>
    </div>
  );
}

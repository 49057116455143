// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feedbackcontent {
  margin-bottom: var(--spacing-s);
}
.feedbacktitle {
  margin-bottom: var(--spacing-xs);
  text-align: center;
}
.feedbackp {
  margin-bottom: var(--spacing-l);
  text-align: center;
}
.feedback__footer {
  margin-top: var(--spacing-s);
  margin-bottom: var(--spacing-m);
}
.feedback__footer button:nth-child(2) {
  margin-top: var(--spacing-s);
}
.feedbackcontainer {
  justify-content: space-between;
}
.feedback__textarea {
  margin-top: var(--spacing-l);
}`, "",{"version":3,"sources":["webpack://./src/pages/Feedback/feedback.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;AACjC;AACA;EACE,gCAAgC;EAChC,kBAAkB;AACpB;AACA;EACE,+BAA+B;EAC/B,kBAAkB;AACpB;AACA;EACE,4BAA4B;EAC5B,+BAA+B;AACjC;AACA;EACE,4BAA4B;AAC9B;AACA;EACE,8BAA8B;AAChC;AACA;EACE,4BAA4B;AAC9B","sourcesContent":[".feedbackcontent {\n  margin-bottom: var(--spacing-s);\n}\n.feedbacktitle {\n  margin-bottom: var(--spacing-xs);\n  text-align: center;\n}\n.feedbackp {\n  margin-bottom: var(--spacing-l);\n  text-align: center;\n}\n.feedback__footer {\n  margin-top: var(--spacing-s);\n  margin-bottom: var(--spacing-m);\n}\n.feedback__footer button:nth-child(2) {\n  margin-top: var(--spacing-s);\n}\n.feedbackcontainer {\n  justify-content: space-between;\n}\n.feedback__textarea {\n  margin-top: var(--spacing-l);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.emergency__page p{
	color: var(--neutral-4);
}
.emergency__page {
	position: relative;
	margin-bottom: 120px;
}
.emergency__bottomwrapper{
	position: fixed;
	bottom: 0;
	width: calc(100vw - var(--spacing-l));
	padding-top: var(--spacing-l);
	padding-bottom: var(--spacing-m);
	background: linear-gradient(180deg, rgba(250, 252, 253, 0) 0%, #FAFCFD 22.22%, #FAFCFD 100%);
}

.emergency__callBtn {
	margin-top: var(--spacing-s);
	color: var(--brand-colors-primary-5);
	text-decoration: none;
	display: block;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Emergency/emergency.css"],"names":[],"mappings":"AAAA;CACC,uBAAuB;AACxB;AACA;CACC,kBAAkB;CAClB,oBAAoB;AACrB;AACA;CACC,eAAe;CACf,SAAS;CACT,qCAAqC;CACrC,6BAA6B;CAC7B,gCAAgC;CAChC,4FAA4F;AAC7F;;AAEA;CACC,4BAA4B;CAC5B,oCAAoC;CACpC,qBAAqB;CACrB,cAAc;EACb,kBAAkB;AACpB","sourcesContent":[".emergency__page p{\n\tcolor: var(--neutral-4);\n}\n.emergency__page {\n\tposition: relative;\n\tmargin-bottom: 120px;\n}\n.emergency__bottomwrapper{\n\tposition: fixed;\n\tbottom: 0;\n\twidth: calc(100vw - var(--spacing-l));\n\tpadding-top: var(--spacing-l);\n\tpadding-bottom: var(--spacing-m);\n\tbackground: linear-gradient(180deg, rgba(250, 252, 253, 0) 0%, #FAFCFD 22.22%, #FAFCFD 100%);\n}\n\n.emergency__callBtn {\n\tmargin-top: var(--spacing-s);\n\tcolor: var(--brand-colors-primary-5);\n\ttext-decoration: none;\n\tdisplay: block;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

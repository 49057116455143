// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.output__container {
  padding: 0;
}

.output__content {
  position: relative;
  top: -48px;
}

.output__green {
  background-color: var(--brand-colors-primary-3);
}

.output__yellow {
  background-color: var(--feedback-colors-yellow);
}

.output__orange {
  background-color: var(--feedback-colors-orange);
}

.output__red {
  background-color: var(--feedback-colors-red);
}

.output__header {
  padding: 32px 16px 64px;
  border-radius: 0px 0px 16px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: none;
}

.output__header__title {
  margin-top: 16px;
}

.output__header__p {
  margin: 8px 0;
}

.output__outputSections > div {
  margin-bottom: 24px;
}

.output__card {
  margin: 32px 16px 0;
  padding: 16px;
  background-color: var(--neutral-1);
  box-shadow: var(--box-shadow);
  border-radius: 16px;
}

.output__card__p {
  padding: 8px 0 16px;
}

.output__button {
  padding: 32px 16px;
}

.output__p {
  padding-left: 16px;
  padding-right: 16px;
  font-size: 12px;
  color: var(--neutral-3);
}
.popup_text {
  color: var(--brand-colors-primary-3);
  font-weight: var(--font-weight-lg);
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/pages/Output/Output.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,+CAA+C;AACjD;;AAEA;EACE,+CAA+C;AACjD;;AAEA;EACE,+CAA+C;AACjD;;AAEA;EACE,4CAA4C;AAC9C;;AAEA;EACE,uBAAuB;EACvB,gCAAgC;EAChC,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,kCAAkC;EAClC,6BAA6B;EAC7B,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,uBAAuB;AACzB;AACA;EACE,oCAAoC;EACpC,kCAAkC;EAClC,0BAA0B;AAC5B","sourcesContent":[".output__container {\n  padding: 0;\n}\n\n.output__content {\n  position: relative;\n  top: -48px;\n}\n\n.output__green {\n  background-color: var(--brand-colors-primary-3);\n}\n\n.output__yellow {\n  background-color: var(--feedback-colors-yellow);\n}\n\n.output__orange {\n  background-color: var(--feedback-colors-orange);\n}\n\n.output__red {\n  background-color: var(--feedback-colors-red);\n}\n\n.output__header {\n  padding: 32px 16px 64px;\n  border-radius: 0px 0px 16px 16px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n  flex: none;\n}\n\n.output__header__title {\n  margin-top: 16px;\n}\n\n.output__header__p {\n  margin: 8px 0;\n}\n\n.output__outputSections > div {\n  margin-bottom: 24px;\n}\n\n.output__card {\n  margin: 32px 16px 0;\n  padding: 16px;\n  background-color: var(--neutral-1);\n  box-shadow: var(--box-shadow);\n  border-radius: 16px;\n}\n\n.output__card__p {\n  padding: 8px 0 16px;\n}\n\n.output__button {\n  padding: 32px 16px;\n}\n\n.output__p {\n  padding-left: 16px;\n  padding-right: 16px;\n  font-size: 12px;\n  color: var(--neutral-3);\n}\n.popup_text {\n  color: var(--brand-colors-primary-3);\n  font-weight: var(--font-weight-lg);\n  text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useTranslation } from 'react-i18next';
import './reassurance.css';
import Nav from '../../components/Nav/Nav';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import ColoredBar from '../../components/ColoredBar/ColoredBar';
import Button from '../../components/Button/Button';

function Reassurance({ callback, previousQuestion, progress }) {
  const { t } = useTranslation();

  return (
    <div className="container">
      <Nav backArrow logo closeButton backArrowCallback={previousQuestion} />
      <ColoredBar />
      <div className="containerContent reassurance__page__content">
        <div className=" reassurance__page__content__text">
          <h3>{t('reassurance.h3')}</h3>
          <p className="reassurance__page__content__text__p">{t('reassurance.contentp')}</p>
        </div>
        <div className="bottom-wrapper_reassurance">
          <Button hierarchy="primary" width="fullWidth" additionalClass=" continue" onClick={callback}>
            {t('continue')}
          </Button>
          <ProgressBar width={100} percent={progress} />
        </div>
      </div>
    </div>
  );
}

export default Reassurance;
